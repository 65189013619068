import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'store/auth-store';

export interface IEmailConfirmedRouteProps {
  redirectPath?: string;
  children?: React.ReactElement;
}

export const EmailConfirmedRoute: React.FC<IEmailConfirmedRouteProps> = ({
  redirectPath = '/access-denied',
  children,
}) => {
  const {
    state: { auth },
  } = useAuth();

  if (!auth?.isEmailConfirmed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

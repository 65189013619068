export const ENDPOINTS = {
  AUTH: {
    LOGIN: '/auth/login',
    LOGOUT: '/auth/logout',
    ME: '/auth/me',
    REFRESH: '/auth/refresh',
    REGISTER: '/auth/register',
    CHANGE_PASSWORD: '/auth/change-password',
  },
  USERS: {
    DATA: '/users/data',
    AVATAR: '/users/avatar',
  },
  LOCAL_FILES: {
    GET: (id: string) => `/local-files/${id}`,
  },
  RESET_PASSWORD: {
    RESET: '/reset-password',
  },
  LAKES: {
    LAKES: '/lakes',
    AVAILABLE: '/lakes/available',
    LAKE: (id: string) => `/lakes/${id}`,
  },
  EMAIL_CONFIRMATION: {
    CONFIRM: '/email-confirmation/confirm',
    RESEND: '/email-confirmation/resend',
  },
  RESERVATIONS: {
    MAKE_RESERVATION: '/reservations',
    RESERVATIONS: '/reservations',
    RESERVATION: (id: string) => '/reservations/' + id,
    PRICE: '/reservations/price',
    UNAVAILABLE_LAKE_POSITIONS: '/reservations/unavailable-lake-positions',
  },
  SCHEDULE: {
    SCHEDULE: '/schedule',
  },
  CONTACT: {
    CONTACT: '/contact',
  },
  RATING: {
    RATING: '/rating',
  },
};

import {
  createStyles,
  Header,
  Container,
  Group,
  Burger,
  Paper,
  Transition,
  Text,
  Menu,
  UnstyledButton,
  Avatar,
  Divider,
  Image,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import {
  IconCalendarEvent,
  IconChevronDown,
  IconLock,
  IconLogout,
  IconSettings,
} from '@tabler/icons-react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { localFilesAPI } from 'services/api/local-files';
import { useAuth } from 'store/auth-store';
import { EXTRA_SMALL_SCREEN_HEADER_LINKS, HEADER_LINKS } from './links';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import truncate from 'lodash/truncate';

const HEADER_HEIGHT = 60;

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    zIndex: 3,
  },

  dropdown: {
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',

    [theme.fn.largerThan('md')]: {
      display: 'none',
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: HEADER_HEIGHT,
  },

  links: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  burger: {
    [theme.fn.largerThan('md')]: {
      display: 'none',
    },
  },

  logoLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
  },

  logoLinkText: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: '8px 12px',
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },

    [theme.fn.smallerThan('md')]: {
      borderRadius: 0,
      padding: theme.spacing.md,
    },
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.fn.rgba(theme.colors[theme.primaryColor][9], 0.25)
          : theme.colors[theme.primaryColor][0],
      color: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 3 : 7],
    },
  },

  user: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
    borderRadius: theme.radius.sm,
    transition: 'background-color 100ms ease',

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
    },
  },

  userActive: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
  },

  userName: {
    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
  },
}));

export const PageHeader = () => {
  const {
    state: { auth },
  } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const [isBurgerMenuOpened, burgerMenuHandlers] = useDisclosure(false);
  const [isUserMenuOpened, userMenuHandlers] = useDisclosure(false);
  const { classes, cx } = useStyles();
  const isExtraSmallScreen = useMediaQuery('(max-width: 355px)');

  const items = useMemo(() => {
    let links = HEADER_LINKS;

    if (!auth && isExtraSmallScreen) {
      links = links.concat(EXTRA_SMALL_SCREEN_HEADER_LINKS);
    }

    return links.map((link) => {
      const isActive = location.pathname === link.link;

      return (
        <Text
          component={NavLink}
          key={link.label}
          to={link.link}
          className={cx(classes.link, { [classes.linkActive]: isActive })}
          onClick={burgerMenuHandlers.close}
        >
          {t(link.label)}
        </Text>
      );
    });
  }, [
    auth,
    isExtraSmallScreen,
    location.pathname,
    cx,
    classes.link,
    classes.linkActive,
    burgerMenuHandlers.close,
    t,
  ]);

  return (
    <Header height={HEADER_HEIGHT} className={classes.root}>
      <Container className={classes.header}>
        <Group>
          <Burger
            opened={isBurgerMenuOpened}
            onClick={burgerMenuHandlers.toggle}
            className={classes.burger}
            size='sm'
            aria-label='otwórz menu'
          />
          <Text component={Link} variant='text' to='/' size='md' className={classes.logoLink}>
            <Image
              src='/logo.svg'
              height={28}
              width={30}
              alt='zarezerwujlowisko.pl'
              aria-label='zarezerwujlowisko.pl'
            />
            <Text className={classes.logoLinkText}>zarezerwujlowisko.pl</Text>
          </Text>
        </Group>

        <Group spacing={5} className={classes.links}>
          {items}
        </Group>

        {!!auth && (
          <Menu
            width={260}
            position='top-end'
            transition='pop-top-right'
            onClose={userMenuHandlers.close}
            onOpen={userMenuHandlers.open}
          >
            <Menu.Target>
              <UnstyledButton
                className={cx(classes.user, { [classes.userActive]: isUserMenuOpened })}
              >
                <Group spacing={7}>
                  <Avatar
                    src={localFilesAPI.getAvatar(auth.avatarId)}
                    radius={20}
                    size={20}
                    alt=''
                  />
                  <Text
                    className={classes.userName}
                    weight={500}
                    size='sm'
                    sx={{ lineHeight: 1 }}
                    mr={3}
                  >
                    {truncate(auth.name + ' ' + auth.surname, {
                      length: 40,
                      omission: '...',
                    })}
                  </Text>
                  <IconChevronDown size={12} />
                </Group>
              </UnstyledButton>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>Menu</Menu.Label>
              <Menu.Item
                sx={() => ({
                  fontWeight: location.pathname === '/user/account-details' ? 500 : 400,
                })}
                onClick={burgerMenuHandlers.close}
                component={NavLink}
                to='/user/account-details'
                icon={<IconSettings size={14} />}
              >
                Dane konta
              </Menu.Item>
              <Menu.Item
                sx={() => ({
                  fontWeight: location.pathname === '/user/my-reservations' ? 500 : 400,
                })}
                onClick={burgerMenuHandlers.close}
                component={NavLink}
                to='/user/my-reservations'
                icon={<IconCalendarEvent size={14} />}
              >
                Moje rezerwacje
              </Menu.Item>
              <Menu.Item
                sx={() => ({
                  fontWeight: location.pathname === '/user/security' ? 500 : 400,
                })}
                onClick={burgerMenuHandlers.close}
                component={NavLink}
                to='/user/security'
                icon={<IconLock size={14} />}
              >
                Bezpieczeństwo
              </Menu.Item>

              <Divider />
              <Menu.Item
                color='red'
                onClick={burgerMenuHandlers.close}
                component={NavLink}
                to='/logout'
                icon={<IconLogout size={14} />}
              >
                Wyloguj się
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        )}
        {!isExtraSmallScreen && !auth && (
          <Group spacing={5}>
            <Text
              component={NavLink}
              to={'/login'}
              className={cx(classes.link, {
                [classes.linkActive]: location.pathname === '/login',
              })}
              onClick={burgerMenuHandlers.close}
            >
              Zaloguj się
            </Text>
            <Text
              component={NavLink}
              to={'/register'}
              className={cx(classes.link, {
                [classes.linkActive]: location.pathname === '/register',
              })}
              onClick={burgerMenuHandlers.close}
            >
              Zarejestruj się
            </Text>
          </Group>
        )}

        <Transition transition='scale-y' duration={200} mounted={isBurgerMenuOpened}>
          {(styles) => (
            <Paper className={classes.dropdown} withBorder style={styles}>
              {items}
            </Paper>
          )}
        </Transition>
      </Container>
    </Header>
  );
};

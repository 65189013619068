import loadable from '@loadable/component';
import { Button, Container, Stack, Text, Title } from '@mantine/core';
import * as Sentry from '@sentry/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
const SadHanging = loadable(() => import('components/atoms/lottie-animations/sad-hanging'), {
  resolveComponent: (component) => component.SadHanging,
});

const DIALOG_OPTIONS: Sentry.ReportDialogOptions = {
  labelName: 'Imię i nazwisko',
  labelEmail: 'Adres email',
  labelComments: 'Opisz proszę co się stało.',
  labelSubmit: 'Wyślij',
  labelClose: 'Zamknij',
  title: 'Coś poszło nie tak...',
  subtitle:
    'Nasz zespół został już poinformowany. Jeżeli chciałbyś nam pomóc, opisz proszę co się stało.',
  subtitle2: '',
  lang: 'pl',
  successMessage: 'Twoje zgłoszenie zostało wysłane, dziękujemy!',
};

const FallbackWrapper: Sentry.FallbackRender = ({ resetError }) => {
  return <FallbackComponent resetError={resetError} />;
};

// TODO: optimize lottie files

const FallbackComponent = ({ resetError }: { resetError: () => void }) => {
  const { t } = useTranslation();

  const handleGoHome = useCallback(() => {
    window.location.href = window.location.origin;
  }, []);

  return (
    <Container>
      <Stack mx='auto' maw='75ch' align='center' justify='center' mt={0} mb={25}>
        <Stack align='center' w='100%'>
          <SadHanging />
        </Stack>
        <Title align='center' order={1}>
          {t('headings.somethingWentWrong')}
        </Title>
        <Text maw='50ch' align='center'>
          {t('paragraphs.sorryForError')}
        </Text>
        <Stack mt={20}>
          <Button type='button' onClick={resetError}>
            {t('buttons.refresh')}
          </Button>
          <Button variant='subtle' type='button' onClick={handleGoHome}>
            {t('buttons.homePage')}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};

interface IErrorBoundaryProps {
  children: React.ReactNode;
}

export const ErrorBoundary: React.FC<IErrorBoundaryProps> = ({ children }) => {
  return (
    <Sentry.ErrorBoundary fallback={FallbackWrapper} showDialog dialogOptions={DIALOG_OPTIONS}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

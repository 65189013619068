import { api } from '..';
import { ENDPOINTS } from '../endpoints';

const getLocalFile = (fileId: string) => {
  if (!api.defaults.baseURL) throw new Error('baseURL not set.');

  return new URL(ENDPOINTS.LOCAL_FILES.GET(fileId), api.defaults.baseURL).href;
};

const getAvatar = (avatarId?: string | null) => {
  if (!avatarId) return null;
  return localFilesAPI.getLocalFile(avatarId);
};

export const localFilesAPI = {
  getAvatar,
  getLocalFile,
};

import yup from 'services/yup';

export const sendRatingSchema = yup
  .object({
    recaptcha: yup.string().required(),
    usabilityRating: yup.number().required(),
    whatsBest: yup
      .string()
      .min(10)
      .max(600)
      .transform((value) => (value ? value : null))
      .nullable()
      .optional(),
    whatToChange: yup
      .string()
      .min(10)
      .max(600)
      .transform((value) => (value ? value : null))
      .nullable()
      .optional(),
    easeOfFinding: yup
      .string()
      .min(10)
      .max(600)
      .transform((value) => (value ? value : null))
      .nullable()
      .optional(),
    easeOfFindingRating: yup.number().required(),
    speedRating: yup.number().required(),
    features: yup
      .string()
      .min(10)
      .max(600)
      .transform((value) => (value ? value : null))
      .nullable()
      .optional(),
  })
  .required();

export type TSendRatingProps = yup.InferType<typeof sendRatingSchema>;

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Modal, Rating, Stack, Text, Textarea } from '@mantine/core';
import { useDisclosure, useLocalStorage } from '@mantine/hooks';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getApiFieldErrorMessage, isApiValidationError } from 'services/api';
import { ratingAPI } from 'services/api/rating';
import { TSendRatingProps, sendRatingSchema } from 'services/api/rating/schema';
import { notificationService } from 'services/notifications';
import { getErrorMessage } from 'utils/getErrorMessage';

const VISIBILITY = {
  VISIBLE: '1',
  HIDDEN: '0',
} as const;

type ValueOf<T> = T[keyof T];

export const Feedback = () => {
  const { t } = useTranslation();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [opened, { open, close }] = useDisclosure(false);
  const [visibility, setVisibility] = useLocalStorage<ValueOf<typeof VISIBILITY>>({
    key: 'zarezerwujlowisko-rating',
    getInitialValueInEffect: false,
    defaultValue: VISIBILITY.VISIBLE,
  });

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm<TSendRatingProps>({
    resolver: yupResolver(sendRatingSchema),
  });

  const { mutateAsync, isLoading, error: apiError } = useMutation(ratingAPI.sendRating);
  console.log(getValues());
  const onRecaptchaChange = useCallback(
    (token?: string | null) => {
      setValue('recaptcha', (token ?? undefined) as string, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    },
    [setValue],
  );

  const submitHandler = useCallback(
    async (data: TSendRatingProps) => {
      try {
        const honeypotValue = (data as any)['contact_me_h'];
        if (honeypotValue) throw new Error('SPAM');
        const { data: contactData } = await mutateAsync(data);

        notificationService.showSuccessNotification({
          message: t('notifications.rating.successMessage'),
        });
        close();
        reset();
      } catch (error) {
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
          onRecaptchaChange(undefined);
        }

        if (isApiValidationError(error)) {
          if (error.statusCode === 429) {
            notificationService.showErrorNotification(
              {
                message: t('notifications.rating.toManyRatingRequests'),
              },
              error,
            );
            return;
          } else {
            return;
          }
        }

        notificationService.showErrorNotification(
          {
            message: t('notifications.rating.errorMessage'),
          },
          error,
        );
      }
    },
    [mutateAsync, t, close, reset, onRecaptchaChange],
  );

  if (visibility === VISIBILITY.HIDDEN) return null;

  return (
    <>
      <Box h='auto' pos='fixed' left={0} top='70%' style={{ transform: 'translateY(-50%)' }}>
        <Button
          size='sm'
          color='dark.4'
          onClick={open}
          style={{
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            willChange: 'transform',
            transform: 'rotate(90deg) translate(-50%, -50%) translateZ(0)',
            transformOrigin: 'left',
            WebkitFontSmoothing: 'antialiased',
          }}
        >
          ⭐ Oceń nas! ⭐
        </Button>
      </Box>
      <Modal
        closeOnClickOutside={false}
        size='xl'
        title='⭐ Oceń nas! ⭐'
        centered
        opened={opened}
        onClose={close}
      >
        <Text mb='xl'>
          Cieszymy się, że poświęciłeś chwilę na ocenę naszej strony! Twoja opinia ma dla nas
          ogromne znaczenie, ponieważ dążymy do ciągłego doskonalenia i dostarczania najlepszego
          doświadczenia dla naszych użytkowników. Poniżej znajduje się krótka ankieta, która pozwoli
          nam lepiej zrozumieć Twoje oczekiwania oraz jak możemy poprawić naszą stronę. Nie musisz
          podawać swoich danych osobowych, wszystkie odpowiedzi są w pełni anonimowe.
        </Text>

        <form onSubmit={handleSubmit(submitHandler)}>
          <input
            type='checkbox'
            {...register('contact_me_h' as any)}
            value='1'
            style={{ display: 'none' }}
            tabIndex={-1}
            autoComplete='off'
          />
          <Stack spacing='md' align='flex-start'>
            <Stack spacing='sm'>
              <Text fz='sm' fw={500}>
                {t('labels.usability')}
                <Text display='inline' color='red'>
                  {' *'}
                </Text>
              </Text>
              <Rating
                fractions={1}
                {...register('usabilityRating')}
                onChange={(value) => {
                  setValue('usabilityRating', value ?? undefined, {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: true,
                  });
                }}
                onBlur={undefined}
              />
            </Stack>

            <Stack spacing='sm' w='100%'>
              <Textarea
                label={t('labels.whatsBest')}
                {...register('whatsBest')}
                error={
                  getApiFieldErrorMessage('whatsBest', apiError) ||
                  (errors?.whatsBest?.message &&
                    getErrorMessage({ t, error: errors?.whatsBest?.message }))
                }
              />
            </Stack>

            <Stack spacing='sm' w='100%'>
              <Textarea
                label={t('labels.whatToChange')}
                {...register('whatToChange')}
                error={
                  getApiFieldErrorMessage('whatToChange', apiError) ||
                  (errors?.whatToChange?.message &&
                    getErrorMessage({ t, error: errors?.whatToChange?.message }))
                }
              />
            </Stack>

            <Stack spacing='sm'>
              <Text fz='sm' fw={500}>
                {t('labels.easeOfFinding')}
                <Text display='inline' color='red'>
                  {' *'}
                </Text>
              </Text>
              <Rating
                fractions={1}
                {...register('easeOfFindingRating')}
                onChange={(value) => {
                  setValue('easeOfFindingRating', value ?? undefined, {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: true,
                  });
                }}
                onBlur={undefined}
              />
              <Textarea
                {...register('easeOfFinding')}
                error={
                  getApiFieldErrorMessage('easeOfFinding', apiError) ||
                  (errors?.easeOfFinding?.message &&
                    getErrorMessage({ t, error: errors?.easeOfFinding?.message }))
                }
              />
            </Stack>

            <Stack spacing='sm'>
              <Text fz='sm' fw={500}>
                {t('labels.speed')}
                <Text display='inline' color='red'>
                  {' *'}
                </Text>
              </Text>
              <Rating
                fractions={1}
                {...register('speedRating')}
                onChange={(value) => {
                  setValue('speedRating', value ?? undefined, {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: true,
                  });
                }}
                onBlur={undefined}
              />
            </Stack>

            <Stack spacing='sm' w='100%'>
              <Textarea
                label={t('labels.features')}
                {...register('features')}
                error={
                  getApiFieldErrorMessage('features', apiError) ||
                  (errors?.features?.message &&
                    getErrorMessage({ t, error: errors?.features?.message }))
                }
              />
            </Stack>

            <Stack spacing={2} align='flex-start'>
              <ReCAPTCHA
                ref={recaptchaRef}
                size='normal'
                sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY as string}
                onChange={onRecaptchaChange}
                onExpired={onRecaptchaChange}
              />
              {!!errors?.recaptcha?.message && (
                <Text fz='xs' color='red' align='left'>
                  {getErrorMessage({ t, error: errors?.recaptcha?.message })}
                </Text>
              )}
              {!!getApiFieldErrorMessage('recaptcha', apiError) && (
                <Text fz='xs' color='red' align='left'>
                  {getApiFieldErrorMessage('recaptcha', apiError)}
                </Text>
              )}
            </Stack>

            <Button mt='xl' type='submit' loading={isLoading}>
              Wyślij
            </Button>
          </Stack>
        </form>
        <Text mt='xl'>
          Dziękujemy za poświęcenie czasu na wypełnienie tej ankiety. Twoja opinia jest dla nas
          niezwykle cenna i pomoże nam w ciągłym doskonaleniu naszej strony. Wierzymy, że dzięki
          Tobie będziemy w stanie stworzyć miejsce, które spełni oczekiwania każdego użytkownika.
          Jeśli masz jeszcze jakieś dodatkowe uwagi lub sugestie, nie wahaj się podzielić nimi z
          nami. Dziękujemy za zaufanie i współpracę!
        </Text>
      </Modal>
    </>
  );
};

import { notificationService } from 'services/notifications';
import { ENDPOINTS } from './endpoints';
import axios from 'axios';
import { AuthService } from '../auth';
import { Text } from '@mantine/core';
import i18n from 'i18n';

export interface IValidationErrorMessageObject {
  field: string;
  translatedErrorMessage: string;
  params: {
    property: string;
  };
}

export interface IApiValidationError {
  error: string;
  message: IValidationErrorMessageObject[];
  statusCode: number;
}
export interface IApiThrottlerError {
  error: string;
  message: string;
  statusCode: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isApiValidationError = (error: unknown): error is IApiValidationError => {
  if (
    error &&
    typeof error === 'object' &&
    Object.hasOwn(error, 'message') &&
    'message' in error &&
    Array.isArray(error?.message) &&
    error?.message?.some(
      (item: IValidationErrorMessageObject | undefined) =>
        item?.translatedErrorMessage && item?.field,
    )
  ) {
    return true;
  }

  return false;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isApiThrottlerError = (error: unknown): error is IApiValidationError => {
  if (
    error &&
    typeof error === 'object' &&
    Object.hasOwn(error, 'statusCode') &&
    Object.hasOwn(error, 'message') &&
    'message' in error
  ) {
    return true;
  }

  return false;
};

export const getApiFieldErrorMessage = (fieldName: string, error: unknown) => {
  if (!isApiValidationError(error)) return;
  const fieldError = error?.message?.find((messageObject) => messageObject?.field === fieldName);

  if (fieldError?.translatedErrorMessage) {
    return fieldError.translatedErrorMessage;
  }

  return;
};

export const GLOBAL_HANDLED_STATUS_CODES = [429];
let isRefreshing = false;

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalConfig = error.config;
    if (error.response.status === 429) {
      notificationService.showErrorNotification({
        message: i18n.t('notifications.toManyRequests.errorMessage'),
      });
    }

    if (error?.response?.status === 422) {
      const messageObject: IValidationErrorMessageObject[] | undefined =
        error?.response?.data?.message;
      if (Array.isArray(messageObject)) {
        const message = messageObject.map((item) => {
          if (!item?.translatedErrorMessage) return null;

          return (
            <Text component='p' key={item.field}>
              {item?.translatedErrorMessage || ''}
            </Text>
          );
        });

        if (message) {
          notificationService.showErrorNotification({
            message,
          });
        }
      }

      isRefreshing = false;
      return Promise.reject(error?.response?.data);
    }

    if (
      [401, 403].includes(error.response.status) &&
      [
        ENDPOINTS.AUTH.REFRESH,
        ENDPOINTS.AUTH.LOGIN,
        ENDPOINTS.AUTH.REGISTER,
        ENDPOINTS.AUTH.LOGOUT,
      ].includes(originalConfig.url)
    ) {
      AuthService.removeAuthData();
      return Promise.reject(error);
    } else if (error.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;

      try {
        if (!isRefreshing) {
          isRefreshing = true;
          const { data: authData } = await api.get(ENDPOINTS.AUTH.REFRESH);
          AuthService.setAuthData(authData);
        }

        isRefreshing = false;
        return axios(originalConfig);
      } catch (_error) {
        isRefreshing = false;
        return Promise.reject(_error);
      }
    } else {
      isRefreshing = false;
      return Promise.reject(error);
    }
  },
);

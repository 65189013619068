import { MantineThemeOverride } from '@mantine/core';

export const theme: MantineThemeOverride = {
  primaryShade: 7,
  datesLocale: 'pl',
  fontSizes: {
    xs: 12,
    sm: 14,
    md: 16,
    lg: 18,
    xl: 22,
  },
  headings: {
    sizes: {
      h1: {
        fontSize: '2.5rem',
        lineHeight: 1.2,
      },
      h2: {
        fontSize: '2rem',
        lineHeight: 1.3,
      },
      h3: {
        fontSize: '1.5rem',
        lineHeight: 1.4,
      },
      h4: {
        fontSize: '1.35rem',
        lineHeight: 1.5,
      },
      h5: {
        fontSize: '1.2rem',
        lineHeight: 1.6,
      },
      h6: {
        fontSize: '1rem',
        lineHeight: 1.6,
      },
    },
  },
  colors: {
    transparent: ['transparent'],
    brand: [
      '#f3e8da',
      '#efe1ce',
      '#e8d1b5',
      '#e0c29d',
      '#d8b384',
      '#c2a177',
      '#977d5c',
      '#6c5a42',
      '#413628',
      '#16120d',
    ],
  },
  primaryColor: 'brand',
  components: {
    Modal: {
      defaultProps: {
        centered: true,
        overlayColor: '#cccccc',
        overlayOpacity: 0.55,
        overlayBlur: 3,
      },
    },
    Container: {
      defaultProps: {
        size: 'xl',
        sizes: {
          xs: 540,
          sm: 720,
          md: 960,
          lg: 1140,
          xl: 1320,
        },
      },
    },
  },
};

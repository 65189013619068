import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'store/auth-store';

export interface GuestOnlyProps {
  redirectPath?: string;
  children?: React.ReactElement;
}

export const GuestOnlyRoute: React.FC<GuestOnlyProps> = ({ redirectPath = '/', children }) => {
  const {
    state: { auth },
  } = useAuth();
  if (auth) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

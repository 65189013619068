import React from 'react';
import { createStyles, Text, Container, ActionIcon, Group, Anchor } from '@mantine/core';
import { IconBrandYoutube, IconBrandInstagram, IconBrandFacebook } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NestedObjectLeaves } from 'types/utils/nested-object-leaves';
type pl = typeof import('../../../../public/locales/pl/translation.json');
import terms from '../../../assets/pdf/regulamin-zarezerwujlowisko-pl.pdf';
import privacyPolicy from '../../../assets/pdf/polityka-prywatnosci-zarezerwujlowisko-pl.pdf';

const useStyles = createStyles((theme) => ({
  footer: {
    paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 2,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  logo: {
    maxWidth: 200,

    [theme.fn.smallerThan('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  description: {
    marginTop: 5,

    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.xs,
      textAlign: 'center',
    },
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  groups: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'repeat( auto-fit, 180px )',
    gridGap: 12,
    marginLeft: 60,

    [theme.fn.smallerThan('sm')]: {
      marginLeft: 0,
      marginTop: 24,
      gridTemplateColumns: 'repeat(2, 1fr)',
      justifyItems: 'center',
    },

    [theme.fn.smallerThan('xs')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
      textAlign: 'center',
    },
  },

  wrapper: {
    width: 160,
  },

  link: {
    display: 'block',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    paddingTop: 3,
    paddingBottom: 3,

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  title: {
    fontSize: theme.fontSizes.lg,
    fontWeight: 500,
    marginBottom: theme.spacing.xs / 2,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
  },

  afterFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing.xl,
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
    },
  },

  social: {
    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.xs,
    },
  },
}));

export type TFooterLabel = NestedObjectLeaves<pl>;

type KeysWithPrefix<T, Prefix extends string> = T extends `${Prefix}${infer S}` ? never : T;

type RemovePrefixes<T, Prefixes extends string[]> = T extends infer K
  ? K extends KeysWithPrefix<K, Prefixes[number]>
    ? K
    : never
  : never;

type TFooterLabelWithoutFAQ = RemovePrefixes<TFooterLabel, ['faq']>;

interface ILinkA {
  label: TFooterLabelWithoutFAQ;
  link: string;
}
interface ILinkB {
  label: TFooterLabelWithoutFAQ;
  href: string;
}

type TPageFooterData = {
  title: TFooterLabelWithoutFAQ;
  links: ILinkA[] | ILinkB[];
}[];

const data: TPageFooterData = [
  {
    title: 'headings.aboutUs2',
    links: [
      {
        label: 'headings.aboutUs',
        link: '/about-us',
      },
    ],
  },
  {
    title: 'headings.informations',
    links: [
      {
        label: 'labels.privacyPolicy',
        href: privacyPolicy,
      },
      {
        label: 'labels.terms',
        href: terms,
      },
    ],
  },
  {
    title: 'labels.myAccount',
    links: [
      {
        label: 'labels.accountDetails',
        link: '/user/account-details',
      },
      {
        label: 'labels.myReservations',
        link: '/user/my-reservations',
      },
      {
        label: 'labels.security',
        link: '/user/security',
      },
    ],
  },
  {
    title: 'labels.help',
    links: [
      {
        label: 'labels.contact',
        link: '/contact',
      },
      {
        label: 'labels.faq',
        link: '/faq',
      },
    ],
  },
];

function isILinkB(link: ILinkA | ILinkB): link is ILinkB {
  return 'href' in link;
}

function isILinkA(link: ILinkA | ILinkB): link is ILinkA {
  return 'link' in link;
}

export const PageFooter = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <footer className={classes.footer}>
      <Container className={classes.inner}>
        <div className={classes.logo}>
          <Text>{t('headings.brand')}</Text>
          <Text size='xs' color='gray.7' className={classes.description}>
            {t('headings.footerText')}
          </Text>
        </div>

        <div className={classes.groups}>
          {data.map((group) => {
            const links = group.links.map((link) => {
              if (isILinkB(link)) {
                return (
                  <Anchor
                    key={link.href + link.label}
                    className={classes.link}
                    component='a'
                    target='_blank'
                    href={link.href}
                  >
                    {t(link.label)}
                  </Anchor>
                );
              }

              if (isILinkA(link)) {
                return (
                  <Text
                    key={link.link + link.label}
                    className={classes.link}
                    component={Link}
                    to={link.link}
                  >
                    {t(link.label)}
                  </Text>
                );
              }

              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const never: never = link;
            });

            return (
              <div className={classes.wrapper} key={group.title}>
                <Text className={classes.title}>{t(group.title)}</Text>
                {links}
              </div>
            );
          })}
        </div>
      </Container>
      <Container className={classes.afterFooter}>
        <Text color='gray.7' size='sm'>
          &copy; 2022 - {new Date().getFullYear()} {t('headings.footerText2')}
        </Text>

        <Group spacing={0} className={classes.social} position='right' noWrap>
          <ActionIcon
            aria-label='Facebook'
            size='lg'
            component='a'
            href={t('socials.facebook')}
            target='_blank'
            rel='noopener'
          >
            <IconBrandFacebook size={18} />
          </ActionIcon>
          <ActionIcon
            size='lg'
            component='a'
            href={t('socials.instagram')}
            target='_blank'
            rel='noopener'
            aria-label='Instagram'
          >
            <IconBrandInstagram size={18} />
          </ActionIcon>
          <ActionIcon
            aria-label='YouTube'
            size='lg'
            component='a'
            href={t('socials.youtube')}
            target='_blank'
            rel='noopener'
          >
            <IconBrandYoutube size={18} />
          </ActionIcon>
        </Group>
      </Container>
    </footer>
  );
};

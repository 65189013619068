import { Affix, Anchor, Box, Button, Group, Stack, Text, Transition } from '@mantine/core';
import { Trans, useTranslation } from 'react-i18next';
import privacyPolicy from '../../../assets/pdf/polityka-prywatnosci-zarezerwujlowisko-pl.pdf';
import { useLocalStorage } from '@mantine/hooks';
import { IconX } from '@tabler/icons-react';

const VISIBILITY = {
  VISIBLE: '0',
  HIDDEN: '1',
} as const;

type ValueOf<T> = T[keyof T];

export const CookieInfo = () => {
  const { t } = useTranslation();
  const [visibility, setVisibility] = useLocalStorage<ValueOf<typeof VISIBILITY>>({
    key: 'zarezerwujlowisko-cookie',
    getInitialValueInEffect: false,
    defaultValue: VISIBILITY.VISIBLE,
  });

  return (
    <Affix position={{ left: 0, bottom: 0 }}>
      <Transition transition='slide-up' mounted={visibility === VISIBILITY.VISIBLE}>
        {(transitionStyles) => (
          <Stack
            style={{ ...transitionStyles, boxShadow: '0px 3px 15px rgba(0,0,0,0.2' }}
            p='xl'
            bg='white'
          >
            <Group>
              <Text fz='lg' fw='bold'>
                Cookie
              </Text>
              <Button
                ml='auto'
                variant='white'
                onClick={() => setVisibility(VISIBILITY.HIDDEN)}
                rightIcon={<IconX size={16} />}
              />
            </Group>
            <Text maw='70ch'>
              <Trans
                t={t}
                i18nKey={'paragraphs.cookieInfo'}
                components={[<Anchor target='_blank' href={privacyPolicy} fw='bold' key='href' />]}
              />
            </Text>
          </Stack>
        )}
      </Transition>
    </Affix>
  );
};

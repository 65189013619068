type pl = typeof import('../../public/locales/pl/translation.json');
import { TFunction } from 'i18next';
import { NestedObjectLeaves } from 'types/utils/nested-object-leaves';

export type TValidationKey = NestedObjectLeaves<pl>;

type KeysWithPrefix<T, Prefix extends string> = T extends `${Prefix}${infer S}` ? never : T;

type RemovePrefixes<T, Prefixes extends string[]> = T extends infer K
  ? K extends KeysWithPrefix<K, Prefixes[number]>
    ? K
    : never
  : never;

type TValidationKeyWithoutFAQ = RemovePrefixes<TValidationKey, ['faq']>;

export interface ValidationError {
  key: TValidationKeyWithoutFAQ;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values?: { [key: string]: any };
}

export interface IGetErrorMessage {
  t: TFunction;
  error: ValidationError | string;
}
export const getErrorMessage = ({ t, error }: IGetErrorMessage) => {
  let text = '';
  if (typeof error === 'string') {
    text = t(error);
  } else if (error) {
    const { key, values = {} } = error;
    text = t(key, values);
  }

  return text;
};

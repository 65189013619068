import { IconAlertCircle, IconCircleCheck, IconCircleX } from '@tabler/icons-react';
import { NotificationProps, showNotification } from '@mantine/notifications';
import axios from 'axios';
import { GLOBAL_HANDLED_STATUS_CODES } from 'services/api';

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

const DEFAULT_NOTIFICATION_OPTIONS: Optional<NotificationProps, 'message'> = {
  autoClose: 3000,
};

const showSuccessNotification = (options: NotificationProps) => {
  showNotification({
    ...DEFAULT_NOTIFICATION_OPTIONS,
    color: 'transparent',
    icon: <IconCircleCheck color='green' size={24} />,
    ...options,
  });
};

const showErrorNotification = (options: NotificationProps, apiError?: unknown) => {
  if (
    axios.isAxiosError(apiError) &&
    apiError.response?.status &&
    GLOBAL_HANDLED_STATUS_CODES.includes(apiError.response?.status)
  ) {
    // * error is handled in interceptor
    return;
  }

  showNotification({
    ...DEFAULT_NOTIFICATION_OPTIONS,
    color: 'transparent',
    icon: <IconCircleX color='red' size={24} />,
    ...options,
  });
};

const showInfoNotification = (options: NotificationProps) => {
  showNotification({
    color: 'transparent',
    icon: <IconAlertCircle color='orange' size={24} />,
    ...options,
  });
};

export const notificationService = {
  showSuccessNotification,
  showErrorNotification,
  showInfoNotification,
};

import * as Sentry from '@sentry/react';

export interface IAuthData {
  id: string;
  name: string;
  surname: string;
  email: string;
  phoneNumber: string;
  address: {
    id: string;
    city: string;
    street: string;
    postcode: string;
    voivodeship?: string;
    timezone?: string;
  };
  avatarId?: string | null;
  isEmailConfirmed: boolean;
}

const getLocalStorageItem = <T>(key: string): T | null => {
  try {
    const localStorageData = localStorage.getItem(key);
    return localStorageData ? JSON.parse(localStorageData) || null : null;
  } catch {
    return null;
  }
};

const getAuthData = () => {
  return getLocalStorageItem<IAuthData | null | undefined>('authData');
};

const setAuthData = (authData?: IAuthData | null) => {
  try {
    if (authData?.id) {
      Sentry.setUser({ id: String(authData.id) });
    }

    localStorage.setItem('authData', authData ? JSON.stringify(authData) : '');
  } catch {
    // err
  }
};

const removeAuthData = () => {
  try {
    localStorage.removeItem('authData');
    Sentry.setUser(null);
  } catch {
    // err
  }
};

export const AuthService = {
  getAuthData,
  setAuthData,
  removeAuthData,
};

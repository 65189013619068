import { AuthService, IAuthData } from '../../auth/index';
import { TSignupProps, TSignInProps, TChangePasswordProps } from './schema';
import { api } from '..';
import { ENDPOINTS } from '../endpoints';

const signup = (userData: TSignupProps) => {
  return api.post(ENDPOINTS.AUTH.REGISTER, userData);
};

const signIn = async (userData: TSignInProps) => {
  const response = await api.post<IAuthData>(ENDPOINTS.AUTH.LOGIN, userData);

  const { data: authData } = response;

  if (authData) {
    AuthService.setAuthData(authData);
    return response;
  } else {
    throw new Error('No data was returned.');
  }
};

const logout = async () => {
  const response = await api.post(ENDPOINTS.AUTH.LOGOUT);
  AuthService.removeAuthData();
  return response;
};

const me = async () => {
  const response = await api.get<IAuthData>(ENDPOINTS.AUTH.ME);
  return response.data;
};

const changePassword = (data: TChangePasswordProps) => {
  return api.patch(ENDPOINTS.AUTH.CHANGE_PASSWORD, data);
};

export const authAPI = {
  signup,
  signIn,
  logout,
  me,
  changePassword,
};

type pl = typeof import('../../../../public/locales/pl/translation.json');
import { NestedObjectLeaves } from 'types/utils/nested-object-leaves';

export type TLabels = NestedObjectLeaves<pl>;

type KeysWithPrefix<T, Prefix extends string> = T extends `${Prefix}${infer S}` ? never : T;

type RemovePrefixes<T, Prefixes extends string[]> = T extends infer K
  ? K extends KeysWithPrefix<K, Prefixes[number]>
    ? K
    : never
  : never;

type TLabelsWithoutFAQ = RemovePrefixes<TLabels, ['faq']>;

interface Link {
  link: string;
  label: TLabelsWithoutFAQ;
}

export const HEADER_LINKS: Link[] = [
  {
    link: '/',
    label: 'buttons.homePage',
  },
  {
    link: '/fishery-list',
    label: 'buttons.fisheryList',
  },
  {
    link: '/reservation/1',
    label: 'buttons.reservation',
  },
];

export const EXTRA_SMALL_SCREEN_HEADER_LINKS: Link[] = [
  {
    link: '/login',
    label: 'buttons.signIn',
  },
  {
    link: '/register',
    label: 'buttons.signup2',
  },
];

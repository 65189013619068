import { TSendRatingProps } from './schema';
import { api } from '..';
import { ENDPOINTS } from '../endpoints';

const sendRating = (data: TSendRatingProps) => {
  return api.post(ENDPOINTS.RATING.RATING, data);
};

export const ratingAPI = {
  sendRating,
};

import qs from 'qs';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export interface IProtectedRouteProps {
  isAllowed: boolean;
  redirectPath?: string;
  children?: React.ReactElement;
}

export const ProtectedRoute: React.FC<IProtectedRouteProps> = ({
  isAllowed,
  redirectPath = '/',
  children,
}) => {
  const lastLocation = useLocation();
  const redirectUrl =
    redirectPath + '?' + qs.stringify({ redirectTo: lastLocation.pathname + lastLocation.search });

  if (!isAllowed) {
    return <Navigate to={redirectUrl} replace />;
  }

  return children ? children : <Outlet />;
};

import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { authAPI } from 'services/api/auth';
import { AuthService, IAuthData } from 'services/auth';

type Action = { type: 'setAuthData'; payload: IAuthData } | { type: 'logout' };

type Dispatch = (action: Action) => void;
type State = { auth: null | IAuthData };
type AuthProviderProps = { children: React.ReactNode };

const AuthStateContext = React.createContext<
  { state: State; dispatch: Dispatch; loading: boolean } | undefined
>(undefined);

const isStateAuthEqualToPayload = (stateAuth: IAuthData, payload: IAuthData) => {
  if (!stateAuth || !payload) {
    return false;
  }
  return !!(
    stateAuth?.id === payload?.id &&
    stateAuth?.name === payload?.name &&
    stateAuth?.surname === payload?.surname &&
    stateAuth?.email === payload?.email &&
    stateAuth?.phoneNumber === payload?.phoneNumber &&
    stateAuth?.address === payload?.address &&
    stateAuth?.address?.id === payload?.address?.id &&
    stateAuth?.address?.street === payload?.address?.street &&
    stateAuth?.address?.city === payload?.address?.city &&
    stateAuth?.address?.postcode === payload?.address?.postcode &&
    stateAuth?.address?.voivodeship === payload?.address?.voivodeship &&
    stateAuth?.avatarId === payload?.avatarId &&
    stateAuth?.isEmailConfirmed === payload?.isEmailConfirmed
  );
};

function authReducer(state: State, action: Action) {
  switch (action.type) {
    case 'setAuthData': {
      AuthService.setAuthData(state.auth);
      if (state.auth && isStateAuthEqualToPayload(state.auth, action.payload)) {
        return state;
      }

      AuthService.setAuthData(action.payload);
      return { auth: action.payload };
    }
    case 'logout': {
      AuthService.removeAuthData();
      return { auth: null };
    }
    default: {
      throw new Error('Unhandled action type');
    }
  }
}

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = React.useReducer(authReducer, {
    auth: AuthService.getAuthData() ?? null,
  });
  const { isLoading } = useQuery(['me', state.auth?.id], authAPI.me, {
    select: (data) => data,
    initialData: () => AuthService.getAuthData() ?? null,
    onSuccess: (authData) => {
      if (!authData) {
        dispatch({ type: 'logout' });
      } else {
        dispatch({ type: 'setAuthData', payload: authData });
      }
    },
    onError: () => {
      dispatch({ type: 'logout' });
    },
    retry: false,
    refetchOnWindowFocus: !!state.auth,
  });

  const value = useMemo(() => ({ state, dispatch, loading: isLoading }), [state, isLoading]);

  return <AuthStateContext.Provider value={value}>{children}</AuthStateContext.Provider>;
}

function useAuth() {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };

import { ModalsProvider } from '@mantine/modals';
import { NotificationsProvider } from '@mantine/notifications';
import * as Sentry from '@sentry/react';
import { EmailConfirmedRoute } from 'components/atoms/email-confirmed-route/email-confirmed-route';
import { GuestOnlyRoute } from 'components/atoms/guest-only-route';
import { PageFooter } from 'components/organisms/page-footer';
import { PageHeader } from 'components/organisms/page-header';
import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, matchPath, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useAuth } from 'store/auth-store';
import { ProtectedRoute } from './components/atoms/protected-route';

import loadable from '@loadable/component';
import { CookieInfo } from 'components/atoms/cookie-info';
import { Feedback } from 'components/organisms/feedback';

const NotFound = loadable(() => import('pages/404'), {
  resolveComponent: (component) => component.NotFound,
});
const AboutUs = loadable(() => import('pages/about-us'), {
  resolveComponent: (component) => component.AboutUs,
});
const AccessDenied = loadable(() => import('pages/access-denied'), {
  resolveComponent: (component) => component.AccessDenied,
});
const ChangePassword = loadable(() => import('pages/change-password'), {
  resolveComponent: (component) => component.ChangePassword,
});
const ConfirmEmail = loadable(() => import('pages/confirm-email'), {
  resolveComponent: (component) => component.ConfirmEmail,
});
const Contact = loadable(() => import('pages/contact'), {
  resolveComponent: (component) => component.Contact,
});
const FAQ = loadable(() => import('pages/faq'), {
  resolveComponent: (component) => component.FAQ,
});
const Fishery = loadable(() => import('pages/fishery'), {
  resolveComponent: (component) => component.Fishery,
});
const FisheryList = loadable(() => import('pages/fishery-list'), {
  resolveComponent: (component) => component.FisheryList,
});
const ForgotPassword = loadable(() => import('pages/forgot-password'), {
  resolveComponent: (component) => component.ForgotPassword,
});
const Home = loadable(() => import('pages/home'), {
  resolveComponent: (component) => component.Home,
});
const Login = loadable(() => import('pages/login'), {
  resolveComponent: (component) => component.Login,
});
const Logout = loadable(() => import('pages/logout'), {
  resolveComponent: (component) => component.Logout,
});
const Register = loadable(() => import('pages/register'), {
  resolveComponent: (component) => component.Register,
});
const Reservation = loadable(() => import('pages/reservation'), {
  resolveComponent: (component) => component.Reservation,
});
const User = loadable(() => import('pages/user'), {
  resolveComponent: (component) => component.User,
});
const AccountDetails = loadable(() => import('pages/user/account-details'), {
  resolveComponent: (component) => component.AccountDetails,
});
const MyReservations = loadable(() => import('pages/user/my-reservations'), {
  resolveComponent: (component) => component.MyReservations,
});
const MyReservationDetails = loadable(
  () => import('pages/user/my-reservations/my-reservation-details'),
  {
    resolveComponent: (component) => component.MyReservationDetails,
  },
);
const Security = loadable(() => import('pages/user/security'), {
  resolveComponent: (component) => component.Security,
});
const UnderConstructionOverlay = loadable(
  () => import('components/organisms/under-construction-overlay'),
  {
    resolveComponent: (component) => component.UnderConstructionOverlay,
  },
);
// ! important
// ! REMOVE SOURCE MAPS
// TODO: add poppins font-family
// TODO: translate routes https://github.com/vlaja/multilanguage-routing-react

function CheckStorageAllowance() {
  let storage;
  try {
    storage = window['localStorage'];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof window.DOMException &&
      (e.code === 22 ||
        e.code === 1014 ||
        e.name === 'QuotaExceededError' ||
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      storage &&
      storage.length !== 0
    );
  }
}
const isLocalStorageAvailable = CheckStorageAllowance();

export const App: React.FC = Sentry.withProfiler(() => {
  const {
    state: { auth },
    loading,
  } = useAuth();
  const { t } = useTranslation();

  const IS_UNDER_CONSTRUCTION = Boolean(
    Number(String(process.env.REACT_APP_IS_UNDER_CONSTRUCTION)),
  );

  return (
    <ModalsProvider>
      <HelmetProvider>
        <NotificationsProvider limit={4} position='top-right'>
          <BrowserRouter>
            <Meta />
            {!!IS_UNDER_CONSTRUCTION && <UnderConstructionOverlay />}

            {!loading && !IS_UNDER_CONSTRUCTION && (
              <>
                <PageHeader />
                <div className='pageWrapper'>
                  <Routes>
                    {/* //* GUEST ONLY ROUTES */}
                    <Route element={<GuestOnlyRoute />}>
                      <Route path='/login' element={<Login />} />
                      <Route path='/register' element={<Register />} />
                      <Route path='/forgot-password' element={<ForgotPassword />} />
                      <Route path='/change-password' element={<ChangePassword />} />
                    </Route>

                    {/* //* PROTECTED ROUTES */}
                    <Route element={<ProtectedRoute isAllowed={!!auth} redirectPath='/login' />}>
                      <Route element={<User />}>
                        <Route path='/user/account-details' element={<AccountDetails />} />
                        <Route path='/user/security' element={<Security />} />

                        {/* //* EMAIL CONFIRMED ROUTES */}
                        <Route element={<EmailConfirmedRoute />}>
                          <Route path='/user/my-reservations' element={<MyReservations />} />
                          <Route
                            path='/user/my-reservations/:reservationId'
                            element={<MyReservationDetails />}
                          />
                        </Route>
                      </Route>

                      <Route path='/confirm-email' element={<ConfirmEmail />} />

                      {/* //* EMAIL CONFIRMED ROUTES */}
                      <Route element={<EmailConfirmedRoute />}>
                        <Route path='/reservation/:step' element={<Reservation />}>
                          <Route path=':fisheryId' element={<Reservation />} />
                        </Route>
                      </Route>
                    </Route>

                    <Route path='/' element={<Home />} />
                    <Route path='/access-denied' element={<AccessDenied />} />
                    <Route path='/logout' element={<Logout />} />
                    <Route path='/faq' element={<FAQ />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/about-us' element={<AboutUs />} />

                    <Route path='/fishery-list' element={<FisheryList />} />
                    <Route path='/fishery/:fisheryId'>
                      <Route path=':slug' element={<Fishery />} />
                      <Route path='' element={<Fishery />} />
                    </Route>

                    <Route path='/404' element={<NotFound />} />
                    <Route path='*' element={<Navigate to='/404' replace />} />
                  </Routes>
                </div>
                <PageFooter />
              </>
            )}
            <ScrollToTop />
            <Feedback />
            {isLocalStorageAvailable && <CookieInfo />}
          </BrowserRouter>
        </NotificationsProvider>
      </HelmetProvider>
    </ModalsProvider>
  );
});

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const root = document.getElementById('root');
    root?.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Meta = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const match = MATCHES.reduce((acc, item) => {
    const pathMatchData = matchPath(item, pathname);
    if (pathMatchData) {
      acc = item;

      // * reservation steps
      if (pathMatchData?.pattern?.path?.includes('/reservation/:step')) {
        acc = '/reservation/:step'.replace(':step', pathMatchData?.params?.step || '1');
      }

      // * reservation steps
      if (pathMatchData?.pattern?.path?.includes('/user/my-reservations')) {
        acc = '/user/my-reservations';
      }
    }

    return acc;
  }, '');

  return (
    <Helmet prioritizeSeoTags>
      <title>{t(`meta.${match}.title` as any)}</title>
      <meta name='description' content={t(`meta.${match}.title` as any)} />
    </Helmet>
  );
};

const MATCHES = [
  '/login',
  '/register',
  '/forgot-password',
  '/change-password',
  '/login',
  '/user/account-details',
  '/user/security',
  '/user/my-reservations',
  '/user/my-reservations/:reservationId',
  '/confirm-email',
  '/reservation/:step',
  '/reservation/:step/:fisheryId',
  '/',
  '/access-denied',
  '/logout',
  '/faq',
  '/contact',
  '/about-us',
  '/fishery-list',
  '/fishery/:fisheryId',
  '/fishery/:fisheryId/:slug',
  '/404',
];

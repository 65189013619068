import { LocaleObject } from 'yup';

export const yupLocale: LocaleObject = {
  mixed: {
    notType: (options) => {
      if (options.type === 'date') {
        return {
          key: 'validations.invalidDate',
          values: options,
        };
      }

      return {
        key: 'validations.invalid',
        values: options,
      };
    },
    default: (options) => ({
      key: 'validations.invalid',
      values: options,
    }),
    required: (options) => ({
      key: 'validations.required',
      values: options,
    }),
  },
  string: {
    email: (options) => ({
      key: 'validations.email',
      values: options,
    }),
    min: (options) => ({
      key: 'validations.stringMin',
      values: options,
    }),
    max: (options) => ({
      key: 'validations.stringMax',
      values: options,
    }),
  },
  number: {},
  date: {},
  boolean: {},
  array: {
    min: (options) =>
      options.min > 1
        ? {
            key: 'validations.arrayMinPlural',
            values: options,
          }
        : {
            key: 'validations.arrayMin',
            values: options,
          },
  },
};

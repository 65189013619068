import { MantineProvider } from '@mantine/core';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from 'components/organisms/error-boundary';
import { theme } from 'config/theme';
import React, { Suspense } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from 'store/auth-store';
import { App } from './App';
import './i18n';
import './scss/global.scss';
import './scss/reset.scss';
import 'react-phone-number-input/style.css';
import './wdyr';

Sentry.init({
  release: 'zarezerwujlowisko@' + process.env.REACT_APP_VERSION,
  debug: false,
  environment: process.env.NODE_ENV,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing(),
    new Sentry.Replay(),
    //
  ],
  enabled: process.env.NODE_ENV === 'production',
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 0.2,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <Suspense fallback={<div>Proszę czekać...</div>}>
      <AuthProvider>
        <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </MantineProvider>
      </AuthProvider>
    </Suspense>
  </QueryClientProvider>,
);
